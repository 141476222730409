@each $state, $value in $theme-colors {
  $alert-background: shift-color($value, $alert-bg-scale);
  $alert-border: shift-color($value, $alert-border-scale);
  $alert-color: shift-color($value, $alert-color-scale);
  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
  }
  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
  }
}

.alert-solid {
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            @include alert-variant($value, $value, #fff);
        }
    }
}

.alert-left {
    border: 0;
    border-left: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-top {
    border: 0;
    border-top: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-right {
    border: 0;
    border-right: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-bottom {
    border: 0;
    border-bottom: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
