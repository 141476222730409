.gradient-bottom-right {
    height: 100vh;
    background: radial-gradient(20% 60% at 50% 50%, #3B8AFF 0%, #0048b3 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;

    .c {
        border-radius: 50%;
        padding: 5rem;
       background: radial-gradient(60% 60% at 50% 50%, transparent 67%, #00000078 100%);
        .xs-circle{
            padding: 250px;
        }
    }

    .xl-circle {
        transform: translate(85px, 183px);
    }
    .lg-circle {
        transform: translate(56px, 41px);
       
    }
    .md-circle {
        transform: translate(-35px, 27px);
    }
    .sm-circle {
        transform: translate(63px, -52px)
    }
    .xs-circle {
        transform:translate(62px, -51px);
    }
}