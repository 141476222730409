body{
  overflow-x: hidden;
}

.table-wrapper {
  height: 83%;
  border: 1px solid var(--borderColor);
  overflow: auto;
}

.table-wrapper table {
  border-spacing: 0;
}

.table-wrapper thead {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.table-wrapper tbody {
  font-size: 14px;
}

.table-wrapper th,
.table-wrapper td {
  padding: 18px 24px;
  white-space: nowrap;
  border-bottom: 1px solid var(--borderColor);
}

.table-wrapper tr:last-child td {
  border-bottom: none;
}


/*
  STICKY ROW
  Normal css box-shadow works for the header as it is a single html element
  */

.table-wrapper tr.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #4ab8b3;
  color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}


/*
  STICKY COLUMN
  Avoid undesirable overlapping shadows by creating a faux shadow on the ::after psudo-element instead of using the css box-shadow property.
  */

.table-wrapper th.sticky,
.table-wrapper td.sticky {
  position: sticky;
  left: 0;
  background: #f5f6fa;

}

@media screen and (max-width:1036px) {

  .table-wrapper th.sticky,
  .table-wrapper td.sticky {
    max-width: 15em;
    white-space: normal;
  }
}

.table-wrapper th.sticky::after,
.table-wrapper td.sticky::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-left: 1px solid var(--borderColor);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);
}

.table-wrapper th.sticky::before,
.table-wrapper td.sticky::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-right: 1px solid var(--borderColor);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}

.sidebar .sidebar-toggle{
  right: -26px;
}

/* styles.css */
.pulse-red {
  animation: pulse-animation-red 2s infinite;
}

@keyframes pulse-animation-red {
  0% {
    box-shadow: 0 0 0 0px rgba(243, 3, 3, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}


.pulse-yellow {
  animation: pulse-animation-yellow 2s infinite;
}

@keyframes pulse-animation-yellow {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 230, 2, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 238, 0, 0);
  }
}


.pulse-green {
  animation: pulse-animation-green 2s infinite;
}

@keyframes pulse-animation-green {
  0% {
    box-shadow: 0 0 0 0px rgba(31, 243, 3, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
}

.pulse-purple {
  animation: pulse-animation-purple 2s infinite;
}

@keyframes pulse-animation-purple {
  0% {
    box-shadow: 0 0 0 0px rgba(127, 3, 243, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(153, 0, 255, 0);
  }
}

#contentBox {
  background-color: white;
}

@media print {
  #contentBox {
    transform: none !important;
    box-shadow: none;
  }
}
