// Sidebar Background Colors
.sidebar {
    &.sidebar-dark {
        background-color: $navbar-vertical-dark-bg;
    }

    &.sidebar-color {
        background-color: $navbar-vertical-color-bg;
    }
}
